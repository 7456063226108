<template>
  <div class="goods-coupon-list">
    <template v-for="(item, index) in list">
      <div class="coupon-item" v-if="index < max" :key="index">
        <div class="coupon-item-head" v-if="!item.memberOnly">
          <span class="coupon-text">券</span>
        </div>
        <!-- <div class="coupon-item-head-vip" v-if="item.memberOnly">
          <span class="coupon-text">券</span>
        </div> -->
        <div class="coupon-item-body">
          <span class="coupon-text">{{ item.title }}</span>
        </div>
      </div>
    </template>
    <template v-if="list.length > 2">...</template>
  </div>
</template>

<script>
export default {
  name: "GoodsCoupon",
  props: {
    max: {
      type: Number,
      default: 2
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.goods-coupon-list {
  display: inline-flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #ff6e4c;
  text-align: left;
  .coupon-item {
    // line-height: 16px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    .coupon-text {
      font-size: 12px;
      // transform: scale(0.9);
    }
    .coupon-item-head {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0 4px;
      height: 20px;
      font-weight: 700;
      border-radius: 2px 0 0 2px;
      background: rgba(255, 244, 241, 0.99);
      border: 1px solid rgba(#ff6e4c, 0.5);
    }
    // .coupon-item-head-vip {
    //   display: flex;
    //   align-items: center;
    //   box-sizing: border-box;
    //   width: 36px;
    //   height: 16px;
    //   padding-left: 16px;
    //   background: url("~@/assets/images/coupon/coupon-item-head-vip.png") no-repeat;
    //   background-size: 100% 100%;
    //   font-weight: 700;
    // }
    .coupon-item-body {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      height: 20px;
      padding: 0 4px;
      border-radius: 0 2px 2px 0;
      background: rgba(255, 244, 241, 0.99);
      border: 1px solid rgba(#ff6e4c, 0.5);
      border-left: none;
    }
  }
}
</style>
